/** @jsx jsx */
import { jsx } from "@emotion/core";
import style from "./VideoPanelStyle";
import { MdPlayCircleOutline } from "react-icons/md";

export function VideoPanelNew({ openVideo, videoId, posterFrameUrl }) {
  return (
    <section>
      <div css={style.videoContainer}>
        <a
          href={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`}
          className='youtube-videos'
          onClick={(e) => {
            e.preventDefault();
            openVideo(0);
          }}
        >
          <MdPlayCircleOutline css={style.playIcon} />
        </a>
        <img
          src={
            "https://mipac.stg5.jaba.com.au/sites/mipac/media/video-thumbnail-images/" +
            posterFrameUrl
          }
          style={{ width: "100%" }}
          alt='v1'
        />
      </div>
    </section>
  );
}
